<template>
<div></div>
</template>

<script>
import store from '@/store'
import router from '@/router'

export default {
  name: 'Callback.vue',

  beforeRouteEnter (to, from, next) {
    const urlLocal = localStorage.getItem('urlShare')

    let titleAd = localStorage.getItem('titleAd')
    const idAd = localStorage.getItem('idAd')

    // content share from invite friend
    let content = '【PR】ポイ活サイトのCHIBIJOB（ちびじょぶ）でお小遣いGET！！\n' +
      '簡単に登録出来て、すぐにお小遣いが稼げちゃう！\n' +
      '今すぐ登録しよう\n' + '#chibijob #ポイ活 #懸賞 #懸賞垢さんと繋がりたい #懸賞仲間募集中\n' + urlLocal

    // content share from advertising
    if (titleAd) {
      const urlShareContent = window.location.origin + `/share-seo/sns/${idAd}?target_url=${urlLocal}&section_id=${Date.now()}`

      // check length character share twitter
      // get length of element fixed of twitter share
      const lengthFixed = 51 + urlShareContent.length

      // the maximum number of characters remaining of the title(max character share is 280), discount 10 character = 270
      const maxLengthTitle = 270 - lengthFixed

      // Compact title if over limit share charater of twitter
      if (maxLengthTitle < titleAd.length) {
        titleAd = titleAd.slice(0, maxLengthTitle) + '...'
      }

      content = '【PR】ポイ活サイトのCHIBIJOB（ちびじょぶ）でお小遣いGET！！\n' +
        `${titleAd}\n` +
        'ミッション達成でポイントGETしよう！\n' + '#chibijob #ポイ活 #懸賞 #懸賞垢さんと繋がりたい #懸賞仲間募集中\n' + urlShareContent
    }

    const {
      oauth_verifier: oAuthVerifier,
      watched_video: watchedVideo
    } = to.query

    const params = {
      oauth_verifier: oAuthVerifier,
      oauth_token: to.query.oauth_token,
      content_share: content,
      device_id: navigator.userAgent,
      watched_video: watchedVideo
    }

    store.dispatch('shareSns/shareTwitter', params).then((res) => {
      localStorage.removeItem('titleAd')
      localStorage.removeItem('urlShare')
      const parseUrlLocal = new URL(urlLocal)
      const path = parseUrlLocal.href.replace(parseUrlLocal.origin, '')
      const pathRedirect = parseUrlLocal.href.includes('advertising-detail')
        ? path : ('/invite-friend')

      // check api to show modal notify
      if (res.status === 200) {
        store.dispatch('advertises/setHandleShare', true)
        localStorage.setItem('notifySuccess', true)
      }
      router.push({
        path: pathRedirect
      })
      next()
    })

    next()
  }
}
</script>

<style scoped>

</style>
